<template>
  <v-card>
    <v-card-title class="text-h5">
      <v-btn
        class="ml-n2 mr-3"
        depressed
        color="primary"
        icon
        rounded
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      Add Emails
    </v-card-title>
    <v-card-text>
      <v-combobox
        v-model="model"
        :items="users"
        :search-input.sync="search"
        hide-selected
        label="Add some user or "
        multiple
        persistent-hint
        item-value="value"
        small-chips
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <v-chip
            v-if="item === Object(item)"
            v-bind="attrs"
            color="grey lighten-3"
            :input-value="selected"
            small
          >
            <span class="pr-2">
              {{ item.value }}
            </span>
            <v-icon small @click="parent.selectItem(item)">
              mdi-close-circle
            </v-icon>
          </v-chip>
        </template>
        <template v-slot:item="{ index, item }">
          <v-text-field
            v-if="editing === item"
            v-model="editing.text"
            autofocus
            flat
            background-color="transparent"
            hide-details
            solo
            @keyup.enter="edit(index, item)"
          ></v-text-field>
          <v-chip
            v-else
            dark
            label
            small
          >
            {{ item.text }}
          </v-chip>
        </template>
      </v-combobox>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" class="white--text mr-3 mb-4 mt-0" rounded :loading="loading" @click="saveEmails">
        SAVE
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import API from "@/services/api"

export default {
  name:"AddEmails",
  props: {
    emails: Array
  },
  data(){
    return{
      error: false,
      errorMsg: null,
      loading: false,
      user: [],
      model: [],
      search: null,
      users: [],
      editing: null,
      editingIndex: -1,
    }
  },
  watch: {
    model (val, prev) {
      if (val.length === prev.length) return;
      this.model = val.map(v => {
        if (typeof v === 'string') {
          v = {
            text: v,
            value: v,
          }
          this.users.push(v)
        }
        return v
      })
    },
  },
  async mounted(){
    await this.getUsers();
  },
  methods:{
    getUsers: async function() {
      try {
        this.error = false;
        this.errorMsg = null;
        this.loading = true;
        const {
          data: { users }
        } = await API.getUsers(this.companyId);
        this.users = users.map(user => ({text: user.name, value: user.email}));
        this.users.forEach(user => {
          for(let email of this.emails){
            if(user.value === email){
              this.model.push(user);
            }
          }
        })
      } catch (error) {
        this.error = true;
        this.errorMsg = error.message;
      } finally {
        this.loading = false;
      }
    },
    close(){
      this.$emit("closeAddEmail");
    },
    edit (index, item) {
      if (!this.editing) {
        this.editing = item
        this.editingIndex = index
      } else {
        this.editing = null
        this.editingIndex = -1
      }
    },
    saveEmails(){
      this.$emit("replaceEmails", this.model);
      this.close();
    }
  }
}
</script>