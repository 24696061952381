<template>
    <div>
        <v-row class="my-1" no-gutters>
            <v-col cols="2" class="d-flex align-center">
                <span>ID</span>
            </v-col>
            <v-col class="d-flex align-center">
                <span class="pl-3">{{ companyId }}</span>
            </v-col>
        </v-row>

        <v-row no-gutters v-if="setting" class="my-1">
            <v-col cols="2" class="d-flex align-center">
                <span>Name</span>
            </v-col>
            <v-col v-if="setting" class="d-flex align-center">
                <v-text-field
                    v-if="setting.generalInfo"
                    flat
                    dense
                    solo
                    @change="save"
                    hide-details
                    v-model="setting.generalInfo.name"
                >
                </v-text-field>
            </v-col>
        </v-row>
        <v-row no-gutters class="my-1">
            <v-col cols="2" class="d-flex align-center">
                <span>Image</span>
            </v-col>
            <v-col v-if="setting" class="d-flex align-center flex-wrap">
                <a
                    v-if="setting.generalInfo.image"
                    class="mx-2"
                    :href="setting.generalInfo.image.imageURL"
                    target="_blank"
                    ><v-img
                        width="50"
                        :src="setting.generalInfo.image.imageURL"
                    >
                    </v-img
                ></a>
                <v-btn
                    @click="dialogImage = true"
                    class="mx-2"
                    depressed
                    fab
                    icon
                    x-small
                    color="primary"
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>

                <ImageForm
                    v-model="dialogImage"
                    :setting="setting"
                    title="Upload Logo"
                    @closeDialog="closeDialog"
                />
            </v-col>
        </v-row>

        <v-row no-gutters v-if="setting" class="align-center my-1">
            <v-col cols="2" class="d-flex align-center">
                <span>Country</span>
            </v-col>
            <v-col cols="4" class="d-flex align-center">
                <v-autocomplete
                    v-if="setting.generalInfo"
                    flat
                    dense
                    :items="countries"
                    solo
                    @change="selectState"
                    hide-details
                    v-model="setting.generalInfo.country"
                >
                </v-autocomplete>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="setting" class="align-center my-1">
            <v-col cols="2" class="d-flex align-center">
                <span>State</span>
            </v-col>
            <v-col cols="4" class="d-flex align-center">
                <v-autocomplete
                    v-if="setting.generalInfo"
                    flat
                    dense
                    :items="states"
                    solo
                    hide-details
                    v-model="setting.generalInfo.state"
                >
                </v-autocomplete>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="setting" class="align-center my-1">
            <v-col cols="2" class="d-flex align-center">
                <span>City</span>
            </v-col>
            <v-col cols="4" class="d-flex align-center">
                <v-combobox
                    v-model="setting.generalInfo.city"
                    :filter="filter"
                    :hide-no-data="!search"
                    :items="this.cities"
                    hide-details
                    :search-input.sync="search"
                    hide-selected
                    required
                    flat
                    solo
                >
                    <template v-slot:item="{ index, item }">
                        <v-text-field
                            v-if="editing === item"
                            v-model="editing.text"
                            autofocus
                            flat
                            background-color="transparent"
                            hide-details
                            solo
                            @keyup.enter="edit(index, item)"
                        ></v-text-field>
                        <span v-else>
                            {{ item.text }}
                        </span>
                        <v-spacer></v-spacer>
                    </template>
                </v-combobox>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="setting" class="my-1">
            <v-col cols="2" class="d-flex align-center">
                <span>Address</span>
            </v-col>
            <v-col class="d-flex align-center">
                <v-text-field
                    v-if="setting.generalInfo"
                    flat
                    dense
                    solo
                    @change="save"
                    hide-details
                    v-model="setting.generalInfo.address"
                >
                </v-text-field>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="2" class="d-flex align-center">
                <span>Language</span>
            </v-col>
            <v-col cols="4" v-if="setting" class="d-flex align-center">
                <v-select
                    :items="languages"
                    flat
                    solo
                    hide-details
                    v-model="setting.generalInfo.language"
                >
                </v-select>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="2" class="d-flex align-center">
                <span>Emails</span>
            </v-col>
            <v-col cols="8" class="d-flex align-center">
                <v-chip
                    color="grey lighten-3"
                    small
                    v-for="(email, index) of emails"
                    :key="index"
                    class="mr-2"
                >
                    <span>
                        {{ email }}
                    </span>
                </v-chip>
                <v-btn icon color="primary" @click="openAddEmail">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <!--ADD EMAILS-->
        <v-dialog v-model="addEmailDialog" persistent max-width="600px">
            <AddEmails
                :emails="emails"
                @closeAddEmail="closeAddEmail"
                @replaceEmails="replaceEmails"
            />
        </v-dialog>
    </div>
</template>
<script>
import ImageForm from '@/components/Settings/ImageForm'
import { mapActions } from 'vuex'
const countrycitystate = require('countrycitystatejson')
import AddEmails from '@/components/Settings/AddEmails'
import _ from 'lodash'

export default {
    name: 'CompanyGeneralInfo',
    props: {
        setting: Object,
    },
    components: {
        ImageForm,
        AddEmails,
    },
    data() {
        return {
            createDialogNew: false,
            companyId: JSON.parse(localStorage.getItem('company')),
            languages: ['Spanish', 'English'],
            loading: false,
            error: false,
            editing: null,
            editingIndex: -1,
            search: null,
            listCountries: [],
            cities: [],
            countries: [],
            states: [],
            errorMsg: null,
            dialogImage: false,
            addEmailDialog: false,
            emails: [],
        }
    },
    created() {
        this.listCountries = countrycitystate.getCountries()
        this.countries = this.listCountries.map(x => x.name)
        if (this.setting) {
            this.pickState()
        }
    },
    mounted() {
        if (this.setting && this.setting.generalInfo.emails) {
            this.emails = _.cloneDeep(this.setting.generalInfo.emails)
        }
    },
    watch: {
        setting(val) {
            if (val && val.generalInfo.emails) {
                this.emails = _.cloneDeep(val.generalInfo.emails)
            }
        },
    },
    methods: {
        addImage() {
            // this.dialogImage = true;
        },
        ...mapActions(['quoteChangesAction']),
        closeDialog() {
            this.dialogImage = false
        },
        save() {
            this.quoteChangesAction(false)
        },
        pickState() {
            if (this.setting.generalInfo.country) {
                this.selectState(this.setting.generalInfo.country)
            }
            if (this.setting.generalInfo.state) {
                this.selectCity(this.setting.generalInfo.state)
            }
        },
        selectCity: function(event) {
            this.cities[0] = { header: 'Select or create one' }
            let data = countrycitystate.getCities(this.shortNameCity, event)
            data.forEach(city => {
                this.cities.push({ text: city })
            })
        },
        filter(item, queryText, itemText) {
            if (item.header) return false
            const hasValue = val => (val != null ? val : '')
            const text = hasValue(itemText)
            const query = hasValue(queryText)

            return (
                text
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1
            )
        },
        edit(index, item) {
            if (!this.editing) {
                this.editing = item
                this.editingIndex = index
            } else {
                this.editing = null
                this.editingIndex = -1
            }
        },
        selectState: function(event) {
            const index = this.listCountries.findIndex(x => x.name === event)
            this.shortNameCity = this.listCountries[index].shortName
            this.states = countrycitystate.getStatesByShort(this.shortNameCity)
            this.quoteChangesAction(false)
        },
        closeAddEmail() {
            this.addEmailDialog = false
        },
        openAddEmail() {
            this.addEmailDialog = true
        },
        replaceEmails(model) {
            this.emails = model.map(e => e.value)
            this.setting.generalInfo.emails = _.cloneDeep(this.emails)
            this.save()
        },
    },
}
</script>

<style scoped>
.text-singleline {
    line-height: 0.75rem;
}
</style>
